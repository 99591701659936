import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        backgroundColor: 'neutral.10',
      },
    },
  },
  colors: {
    neutral: {
      10: '#F8FAFC',
      20: '#F1F5F9',
      30: '#E2E8F0',
      40: '#CBD5E1',
      50: '#94A3B8',
      60: '#64748B',
      70: '#475569',
      80: '#334155',
      90: '#1E293B',
      100: '#0F172A',
    },
  },
});

export { theme };
