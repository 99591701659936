import { BrowserRouter } from 'react-router-dom';

// import { VStack } from "@chakra-ui/react";

import { AppProvider } from './providers/AppProvider';
import { SuspenseFallbackProvider } from './providers/SuspenseFallbackProvider';
import { Router } from './routes/Router';
//TODO: Temporary blank everything
// function App(){
//   return(
//     <VStack>
//       <h1>M3dtools Coming Soon!</h1>
//       <p>Under Construction</p>
//     </VStack>
//   )
// }
function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <SuspenseFallbackProvider>
          <Router />
        </SuspenseFallbackProvider>
      </BrowserRouter>
    </AppProvider>
  );
}

export { App };
